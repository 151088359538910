import Wai from "../wai";
import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import Modal from "../modal";
import Quiz from "../quiz";
import Stopwatch from "../stopwatch";

let waiContainer = document.querySelector('.js-wai');
if(waiContainer !== null) {
    new Wai(waiContainer);
}

let splideNews = document.querySelector('.js-splide-news');
if(splideNews !== null) {
        new Splide(splideNews, {
            arrows: true,
            autoHeight: false,
            autoWidth: false,
            autoplay: false,
            drag: true,
            pagination: false,
            type: "loop",
            trimSpace: true,
            gap: '40px',
            padding: '0px',
            perPage: 5,
            perMove: 1,
            breakpoints: {
                1200: {
                    perPage: 3,
                },
                800: {
                    perPage: 2,
                },
                600: {
                    perPage: 1,
                },
            }
        }).mount();
}

let splideAutoScroll = document.querySelector('.js-autosplide');
if(splideAutoScroll !== null) {
    new Splide(splideAutoScroll, {
        type: 'loop',
        drag: 'free',
        pagination: false,
        gap: '60px',
        arrows: false,
        perPage: 8,
        autoHeight: true,
        autoWidth: true,
        autoScroll: {
            speed: 1,
        },
        breakpoints: {
            1200: {
                perPage: 3,
            },
            800: {
                perPage: 4,
            },
            600: {
                perPage: 2,
            },
        }
    }).mount( { AutoScroll } );
}

let modals = document.querySelectorAll('.js-modal');
if(modals.length  > 0) {
    modals.forEach(modal => {
        new Modal(modal);
    })
}
let quizes = document.querySelectorAll('.js-quiz');
if(quizes.length  > 0) {
    quizes.forEach(quiz => {
        new Quiz(quiz);
    })
}
let stopwatch = document.querySelector('.js-stopwatch');
new Stopwatch( stopwatch, '.js-timer');



