import * as basicLightbox from "basiclightbox";

class Quiz {
    constructor(selector) {
        this.selector = selector;

        this.question = selector.querySelector('[data-element="question"]');
        this.answersContainer = selector.querySelector('[data-element="answers"]');
        this.nextButton = selector.querySelector('[data-element="next"]');
        this.clearButton = selector.querySelector('[data-element="clear"]');
        this.scoreText = selector.querySelector('[data-element="score"]');

        this.questionCount = selector.getAttribute('data-count');
        this.quiz = selector.getAttribute('data-id');

        this.scoreText.style.display = 'none';

        this.currentQuestion = 0;
        this.score = 0;

        this._nextQuestion = this._nextQuestion.bind(this);
        this._clearQuiz = this._clearQuiz.bind(this);

        this.nextButton.addEventListener('click', this._nextQuestion);
        this.clearButton.addEventListener('click', this._clearQuiz);
    }

    _nextQuestion(event){
        event.preventDefault();
        let checkedAnswer = this._getCheckedAnswer();
        if(checkedAnswer !== -1){
            this._checkAnswer(checkedAnswer).then(result => {
                if(result.correct){
                    this.score++;
                }
                if(result.nextQuestion !== null){
                    this._generateNextQuestion(result.nextQuestion);
                    this.currentQuestion++;

                    if (this.currentQuestion === this.questionCount-1) {
                        this.nextButton.innerText = 'Zakończ quiz';
                    }
                } else {
                    this._showScore();
                    if(this.score == this.questionCount){
                        this.nextButton.style.display = 'none';
                    } else {
                        this.clearButton.style.display = 'block';
                        this.nextButton.style.display = 'none';
                    }
                }
            }).catch(error => {
                this._handleError(error);
            })
        } else {
            this._handleError('no_answer_selected');
        }
    }

    _generateNextQuestion(question){
        if(question.answers.length > 0){
            this.question.innerText = question.question;
            this.answersContainer.innerHTML = '';
            question.answers.forEach((answer, key) => {
                let answerElement = this._createAnswerElement(key, answer.answer);
                this.answersContainer.appendChild(answerElement);
            });
        }
    }

    _showScore(){
        this.selector.classList.add('lesson__box-content--quiz-overlay');
        this.scoreText.style.display = 'flex';
        this.scoreText.innerText = 'Twój wynik: ' + this.score + '/' + this.questionCount;
    }

    _handleError(error){
        console.log(error);
        let element = document.createElement('div');

        const modal = document.createElement('div');
        const close = document.createElement('a');
        close.classList.add('modal__close');
        close.innerHTML = '×';

        modal.classList.add('modal');
        modal.classList.add('modal--quiz-error');
        modal.setAttribute('data-element', 'modal');

        if (error === 'no_answer_selected') {
            modal.innerText = 'Musisz wybrać odpowiedź.';
        } else {
            modal.innerText = 'Wystąpił błąd, odśwież stronę i spróbuj ponownie.';
        }

        modal.appendChild(close);
        element.appendChild(modal);

        let instance = basicLightbox.create(element);
        instance.show();

        close.addEventListener('click', (e) => {
            instance.close();
            instance.innerHTML = '';
        });
        // alert(error);
    }

    _createAnswerElement(id, answer){
        let answerElement = document.createElement('div');
        answerElement.classList.add('lesson__quiz-answer');
        let input = document.createElement('input');
        input.setAttribute('type', 'radio');
        input.setAttribute('name', 'answer');
        input.setAttribute('value', id);
        input.setAttribute('id', 'answer-'+id);
        answerElement.appendChild(input);
        let label = document.createElement('label');
        label.setAttribute('for', 'answer-'+id);
        if(this._isValidURL(answer)){
            let image = document.createElement('img');
            image.setAttribute('src', answer);
            label.appendChild(image);
        } else {
            label.innerHTML = answer;
        }
        answerElement.appendChild(label);
        return answerElement;
    }

    _isValidURL(u){
        let elm = document.createElement('input');
        elm.setAttribute('type', 'url');
        elm.value = u;
        return elm.validity.valid;
    }

    _getCheckedAnswer(){
        let checked = this.answersContainer.querySelector('[name="answer"]:checked');
        if(checked !== null){
            return checked.getAttribute('value');
        }
        return -1;
    }

    _checkAnswer(answer){
        let url = '/wp-json/netivo/v1/quiz/'+this.quiz;
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            body: 'question='+this.currentQuestion+'&answer='+answer,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _getQuestion(question){
        let url = '/wp-json/netivo/v1/quiz/'+this.quiz+'?question='+question;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _clearQuiz(event){
        event.preventDefault();
        this.score = 0;
        this.currentQuestion = 0;
        this.clearButton.style.display = 'none';
        this.scoreText.style.display = 'none';
        this.nextButton.innerText = 'Dalej';
        this.nextButton.style.display = 'block';
        this.selector.classList.remove('lesson__box-content--quiz-overlay');
        this.scoreText.innerText = '';

        this._getQuestion(0).then(question => {
            this._generateNextQuestion(question);
        }).catch(error => {
            this._handleError(error);
        });
    }
}

export default Quiz;