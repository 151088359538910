class Stopwatch {

    constructor(startButton, timerSelector) {
        this.startButton = startButton;
        this.timer = document.querySelectorAll(timerSelector);

        this._startCounting = this._startCounting.bind(this);

        if(this.timer.length > 0 && this.startButton !== null) {
            this.startButton.addEventListener('click', this._startCounting); // w niej podpięty start
        }

        this.state = "paused";
        this.delay = 1000;
        this.value = 0;
    }

    _startCounting(event) {
       event.preventDefault();
            if (this.state == "paused") { this.start();}
            else { this.stop(); }
    }
    formatTime(ms){

        var minutes = Math.floor(ms / 60000);
        var seconds = Math.floor((ms - (minutes * 60000)) / 1000);
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}

        return minutes+':'+seconds;
    }

    update() {
        if (this.state=="running") {
            this.value += this.delay;
        }
        this.timer.forEach(timer => {
            timer.innerHTML = this.formatTime(this.value);
        });
    }

    start() {

        if (this.state=="paused") {
            this.state="running";
            this.startButton.innerHTML = "zatrzymaj";
            if (!this.interval) {
                var t=this;
                this.interval = setInterval(function(){t.update();}, this.delay);
            }
        }
    }

    stop() {

        if (this.state=="running") {
            this.state="paused";
            this.startButton.innerHTML = "rozpocznij";
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        }
    }

}
export default Stopwatch;


